import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { FoIconComponent } from '@ui/components/fo-icon/fo-icon.component';
import { ConfirmDialogConfig } from './models/dialog.config';

@Component({
  standalone: true,
  selector: 'fo-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [
    CommonModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    // Commons
    FoIconComponent,
  ],
})
export class ConfirmDialogComponent {
  defaultDialogConfig: ConfirmDialogConfig = {
    title: 'Unsaved Changes',
    content: 'There are unsaved changes left. If you proceed, they will be lost',
    continueButtonLabel: 'Proceed',
    closeButtonLabel: 'Close',
    discardButtonLabel: 'Discard',
    continueButtonColor: 'primary',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogConfig: ConfirmDialogConfig,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  onContinueClick(): void {
    this.dialogRef.close('continue');
  }

  onCancelClick() {
    this.dialogRef.close('close');
  }

  onDiscardClick() {
    this.dialogRef.close('discard');
  }
}
